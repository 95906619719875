<template>
<div class="c-app flex-row align-items-center bg-recuperar">
    <CContainer>
        <CRow class="justify-content-center">
            <CCol md="4">
                <CCardGroup>
                    <CCard class="p-4">
                        <CCardBody>
                            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                                <b-form @submit.stop.prevent="handleSubmit(enviarCorreo)">
                                    <div class="text-center mt-3 mb-3">
                                    <!-- <img src="img/logo_sig_black.png"
                    alt="logo sig" width="200" > -->
                                    <img src="img/logo_sig.png" alt="logo sig" width="300px" class="img-fluid">
                                    <h5 class="text-center mb-4 text-muted">Recuperar Contraseña</h5>
                                </div>
                                <!--<p class="text-muted">Sign In to your account</p>-->
                                <b-row>
                                    <b-col lg="12">
                                        <validation-provider name="correo electrónico" :rules="{required: true}" v-slot="validationContext">
                                            <b-input-group>
                                                <b-input-group-prepend>
                                                <b-button variant="light"><b-icon icon="envelope-fill"></b-icon></b-button>
                                                </b-input-group-prepend>
                                                <b-form-input size="md" type="text" :state="getValidationState(validationContext)" placeholder="Ingrese su correo electrónico" v-model="correo"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-input-group>
                                        </validation-provider>
                                    </b-col>
                                </b-row>
                                
                                <CRow>
                                    <CCol col="12" class="text-center mt-3">
                                        <CButton color="success" type="submit" class="px-4">Enviar Email</CButton>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol col="12" class="text-center">
                                        <CCol col="12" class="text-center">
                                            <CButton color="link" :to="{name:'Login'}" class="px-0">Volver</CButton>
                                        </CCol>
                                    </CCol>
                                </CRow>
                                </b-form>
                            </validation-observer>
                        </CCardBody>
                    </CCard>
                    <!--<CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h2>Sign up</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <CButton
                  color="light"
                  variant="outline"
                  size="lg"
                >
                  Register Now!
                </CButton>
              </CCardBody>
            </CCard>-->
                </CCardGroup>
            </CCol>
        </CRow>
    </CContainer>
</div>
</template>

<script>
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
// library.add(faEnvelope)
export default {
    name: 'Recuperar',
    data() {
        return {
            correo: ''
        };
    },
    methods: {
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        enviarCorreo() {
            
        }
    },
    mounted() {
    }
}
</script>

<style scoped>
.bg-recuperar{
  background-image: url('../../../public/img/background_recuperar.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

</style>